function transformFigureToUpdateModel(figure) {
  return {
    name: {
      en: figure.name.en,
      bg: figure.name.bg
    },
    image: figure.image,
    goldPrice: figure.goldPrice,
    price: figure.price,
    new: figure.new,
    default: figure.default
  };
}

export default {
  transformFigureToUpdateModel
};
