<template>
  <v-card>
    <v-card-title>
      <span class="headline font-weight-light" v-if="figure.id">
        {{ $t('figures.editFigure') }}
      </span>
      <span class="headline font-weight-light" v-else>
        {{ $t('figures.createFigure') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form ref="manageFigureForm">
        <h3 class="pb-1">{{ $t('common.name') }}</h3>
        <v-text-field
          solo
          v-model="figure.name.en"
          :rules="[rules.required]"
          :label="$t('languages.en')"
          class="pl-3"
        ></v-text-field>
        <v-text-field
          solo
          v-model="figure.name.bg"
          :rules="[rules.required]"
          :label="$t('languages.bg')"
          class="pl-3"
        ></v-text-field>
        <h3 class="pb-1">{{ $t('common.image') }}</h3>
        <v-text-field
          solo
          class="pa-0 my-0"
          v-model="figure.image"
          :rules="[rules.required]"
          :label="$t('common.image')"
        ></v-text-field>
        <h3 class="pb-1">{{ $t('common.price') }}</h3>
        <v-text-field
          solo
          type="number"
          :label="$t('common.price')"
          v-model="figure.price"
        ></v-text-field>
        <h3 class="pb-1">{{ $t('common.goldPrice') }}</h3>
        <v-text-field
          solo
          type="number"
          :label="$t('common.goldPrice')"
          v-model="figure.goldPrice"
        ></v-text-field>
        <v-row>
          <v-col md="3">
            <v-checkbox
              class="mt-0"
              v-model="figure.new"
              :label="$t('common.new')"
              color="primary"
            ></v-checkbox>
          </v-col>
          <v-col md="3">
            <v-checkbox
              class="mt-0"
              v-model="figure.default"
              :label="$t('common.default')"
              color="primary"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '../../../core/validators';

export default {
  name: 'manage-figure-modal',
  props: {
    selectedFigure: Object
  },
  data() {
    return {
      figure: {
        name: {
          en: '',
          bg: ''
        },
        image: '',
        price: 0,
        goldPrice: 0,
        new: false,
        default: false
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  created() {
    if (this.selectedFigure) {
      this.figure = this.selectedFigure;
    }
  },
  methods: {
    async save() {
      if (!this.$refs.manageFigureForm.validate()) {
        return;
      }

      this.$emit('close', this.figure);
    }
  }
};
</script>
